// Manager de fichier uploadés dans un formulaire
const inputFile = {
    init: function () {
        let self = this;

        const fileSelect = document.getElementById("file-label"),
            fileElem = document.getElementById("file");
        
        if (fileElem) {
            fileSelect.addEventListener("click", (e) => {
                if (fileElem) {
                    fileElem.click();
                }
                e.preventDefault(); // prevent navigation to "#"
            }, false);
    
            fileElem.addEventListener("change", self.handleFiles, false);
        }
    },

    handleFiles: function () {
        let fileList = document.getElementById("file-list");

        if (!this.files.length) {

            fileList.innerHTML = "<p>Aucun fichier sélectionné</p>";

        } else {
            
            if (this.files.length < 3) {
                fileList.innerHTML = "";
                const list = document.createElement("ul");
                fileList.appendChild(list);

                for (let i = 0; i < this.files.length; i++) {
                    
                    let filesize = ((this.files[i].size / 1024) / 1024).toFixed(4); // Converti en Mo
                    
                    // Check le poids du fichier. Il doit être < 2Mo
                    if (filesize <= 2) {

                        const li = document.createElement("li");
                        list.appendChild(li);
        
                        const img = document.createElement("img");
                        img.src = URL.createObjectURL(this.files[i]);
                        img.height = 60;
                        img.onload = () => {
                            URL.revokeObjectURL(img.src);
                        }
                        li.appendChild(img);
                        const info = document.createElement("span");
                        info.innerHTML = `${this.files[i].name} : ${Math.round((this.files[i].size/1024))} Ko`;
                        li.appendChild(info);

                    } else {

                        fileList.innerHTML = "<p><strong>Un des fichier est trop lourd. Maximum 2Mo</strong></p>";
                    
                    }
                }

            } else {

                fileList.innerHTML = "<p><strong>Vous ne pouvez pas envoyer plus de 2 fichiers</strong></p>";

            }

        }
    }  
}

export { inputFile };