import Swiper, {Pagination, Navigation} from 'swiper';
Swiper.use([Pagination, Navigation]);

const bricksPage = {
    readyFn: function () {
        this.brickBlocsTextesCarrousel();
        this.brickBlocsImageTitreCarrousel();
    },

    brickBlocsTextesCarrousel: function () {
        if ($('.brick-swiper-blocs-texts').length) {
            $('.brick-swiper-blocs-texts').each(function () {
                let swiperId = $(this).attr('id');
                let prevBtn = document.getElementById(swiperId).querySelector('.swiper-button-prev');
                let nextBtn = document.getElementById(swiperId).querySelector('.swiper-button-next');

                let params = {
                    spaceBetween: 0,
                    slidesPerView: 'auto',
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    navigation: {
                        prevEl: prevBtn,
                        nextEl: nextBtn,
                    }
                }

                let slider = new Swiper('#' + swiperId, params);
            });
        }
    },
    brickBlocsImageTitreCarrousel: function () {
        if ($('.brick-swiper-blocs-image-titre').length) {
            $('.brick-swiper-blocs-image-titre').each(function () {
                let swiperId = $(this).attr('id');
                let prevBtn = document.getElementById(swiperId).querySelector('.swiper-button-prev');
                let nextBtn = document.getElementById(swiperId).querySelector('.swiper-button-next');

                let params = {
                    spaceBetween: 0,
                    slidesPerView: 'auto',
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    navigation: {
                        prevEl: prevBtn,
                        nextEl: nextBtn,
                    }
                }

                let slider = new Swiper('#' + swiperId, params);
            });
        }
    }
}

export { bricksPage };