export default class ScrollToLink {
    init() {
        let self = this;

        // Au chargement, on verifie s'il y a une ancre dans l'url de la page. S'il y en a une, on scroll vers cette ancre
        let curentURL = window.location.href;
        if (curentURL.search('#') != -1) {
            let link = curentURL.split('#')[1].split('/')[0];

            if (link) {
                self.scrollTo('#' + link);
            }
        }

        $('.scroll-to').on('click', (e) => {
            e.preventDefault();

            let link = e.target.getAttribute('href');
            self.scrollTo(link);
        });
    }

    scrollTo(link) {
        if ($(link).length) {
            let headerHeight = document.getElementsByTagName('header')[0].offsetHeight;

            $('html, body').animate({
                scrollTop: ($(link).offset().top - headerHeight) + 'px'
            }, 400);
        }
    }
}